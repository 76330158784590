import React, { useEffect } from 'react';

// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';


// Material UI
import Grid from '@material-ui/core/Grid';

// Actions =>

// Components =>

const Footer = () => {
    useEffect(() => {

    },[]);

    return(
        <footer>
            {/* <Grid container className="icons-area">
                <Grid item xs={12}>
                    <h2>CAT CUBIERTAS DE ALTA TECNOLOGÍA</h2>
                    <h3>Diseñadas y creadas para llevar la experiencia funcional y estética a un máximo nivel a partir de la evolución e innovación de los materiales. Su composición y estructura fue desarrollada para lograr superficies impermeables y cero porosas.</h3>
                    <div className="icons-holder">
                        <div className="icon">
                            <a href="" target="_blank" rel="noreferrer">
                                <img src="https://kober.com.mx/images/cat/resistencia-antimicrobiana.png"/>
                                <b>PROTECCIÓN</b>
                                <p>antimicrobiana Cubiertas higiénicas</p>
                            </a>
                        </div>
                        <div className="icon">
                            <a href="" target="_blank" rel="noreferrer">
                                <img src="https://kober.com.mx/images/cat/impermeabilidad.png"/>
                                <b>IMPERMEABILIDAD</b>
                                <p>0% porosidad Cubiertas higiénicas</p>
                            </a>
                        </div>
                        <div className="icon">
                            <a href="" target="_blank" rel="noreferrer">
                                <img src="https://kober.com.mx/images/cat/resistencia-calor.png"/>
                                <b>RESISTENCIA</b>
                                <p>Al calor</p>
                            </a>
                        </div>
                        <div className="icon">
                            <a href="" target="_blank" rel="noreferrer">
                                <img src="https://kober.com.mx/images/cat/proteccion.png"/>
                                <b>PROTECCIÓN</b>
                                <p>a los choques térmicos</p>
                            </a>
                        </div>
                        <div className="icon">
                            <a href="" target="_blank" rel="noreferrer">
                                <img src="https://kober.com.mx/images/cat/resistencia-olores.png"/>
                                <b>RESISTENCIA</b>
                                <p>a los olores</p>
                            </a>
                        </div>
                        <div className="icon">
                            <a href="" target="_blank" rel="noreferrer">
                                <img src="https://kober.com.mx/images/cat/resistencia-impactos.png"/>
                                <b>RESISTENCIA</b>
                                <p>a los impactos</p>
                            </a>
                        </div>
                        <div className="icon">
                            <a href="https://kober.com.mx/" target="_blank" rel="noreferrer">
                                <img src="https://kober.com.mx/images/cat/resistencia-manchas.png"/>
                                <b>RESISTENCIA</b>
                                <p>a las manchas</p>
                            </a>
                        </div>
                    </div>
                </Grid>
            </Grid> */}

            {/* MAP */}

            {/* SITEMAP */}
            <Grid container className="site-map">
                <Grid item lg={4} md={12} sm={12} xs={12}>
                    <Grid container>
                        <Grid item md={6} sm={12} xs={12}>
                            <a className="logo" href="https://kober.com.mx/" target="_blank" rel="noreferrer">
                                <img src="https://kober.com.mx/images/logo-white.svg" />
                            </a>
                            <nav className="social-icons">
                                <a href="https://www.facebook.com/CubiertasKober/" target="_blank" rel="noreferrer">
                                    <i className="fab fa-facebook"></i>
                                </a>
                                <a href="https://www.youtube.com/user/CubiertasKober/" target="_blank" rel="noreferrer">
                                    <i className="fab fa-youtube"></i>
                                </a>
                                <a href="https://www.instagram.com/inspiracionkober/" target="_blank" rel="noreferrer">
                                    <i className="fab fa-instagram"></i>
                                </a>
                                <a href="https://www.pinterest.com.mx/KOBERMx/" target="_blank" rel="noreferrer">
                                    <i className="fab fa-pinterest"></i>
                                </a>
                            </nav>
                        </Grid>
                        <Grid item md={2} sm={0} xs={0}></Grid>
                        <Grid item md={4} sm={12} xs={12}>
                            <h2>MAPA DEL SITIO</h2>
                            <ul>
                                <li>
                                    <a target="_blank" rel="noreferrer" href="">Inicio</a>
                                </li>
                                <li>
                                    <a target="_blank" rel="noreferrer" href="">Proyectos</a>
                                </li>
                                <li>
                                    <a target="_blank" rel="noreferrer" href="">Productos</a>
                                </li>
                                <li>
                                    <a target="_blank" rel="noreferrer" href="">Bolsa de trabajo</a>
                                </li>
                                <li>
                                    <a target="_blank" rel="noreferrer" href="">Novedades</a>
                                </li>
                                <li>
                                    <a target="_blank" rel="noreferrer" href="">Haz tu cocina</a>
                                </li>
                                <li>
                                    <a target="_blank" rel="noreferrer" href="">Blog</a>
                                </li>
                                <li>
                                    <a target="_blank" rel="noreferrer" href="">Contacto</a>
                                </li>
                                <li>
                                    <a target="_blank" rel="noreferrer" href="">Aviso de privacidad</a>
                                </li>
                                <li>
                                    <a target="_blank" rel="noreferrer" href="">Preguntas frecuentes</a>
                                </li>
                            </ul>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item lg={1} md={12} sm={12} xs={12}></Grid>
                <Grid item lg={7} md={12} sm={12} xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <h2>ENCUÉNTRANOS</h2>
                        </Grid>
                        <Grid item md={4}>
                            <address>
                                <h3>MUNDO KOBER GDL</h3>
                                <a href="tel:3310005388">33 1000 5388</a>
                                <p>Calz del Federalismo Sur 369. Zona Centro 44100 Guadalajara, Jalisco.</p>
                            </address>
                            <address>
                                <h3>MUNDO KOBER MONTERREY</h3>
                                {/* <a href="tel:81 1511 5031">81 1511 5031</a> */}
                                <a href="tel:8134718090">81 3471 8090</a>
                                <p>Moctezuma No. 1616 Monterrey, Nuevo León.</p>
                            </address>
                            <address>
                                <h3>MUNDO KOBER QUERETARO</h3>
                                <a href="tel:4422241544">44 2224 1544</a>
                                <p>PROL. Corregidora sur #61 Col. Villas del sur, 76040 Querétaro, Qro.</p>
                            </address>
                        </Grid>
                        <Grid item md={4}>
                            <address>
                                <h3>MUNDO KOBER LEÓN</h3>
                                <a href="tel:47 7859 2276">47 7859 2276</a>
                                <a href="tel:47 7557 6126">47 7557 6126</a>
                                <p>Blvd. Guanajuato 837, Las Trojes, 37227 León, Guanajuato.</p>
                            </address>
                            <address>
                                <h3>CENTRO DE DISTRIB. CDMX</h3>
                                <a href="tel:33 3101 7490">33 3101 7490 </a>
                                <p>Abundio Martínez 221, Gustavo A. Madero, 07870 CDMX.</p>
                            </address>
                        </Grid>
                        <Grid item md={4}>
                            <address>
                                <h3>AGUASCALIENTES</h3>
                                <a href="tel:44 9123 6952">44 9123 6952</a>
                                <p>Av. Héroe de Nacozari 1502 norte, Colonia Gremial 20030, Aguascalientes, Ags.</p>
                            </address>
                            <address>
                                <h3>PLANTA KOBER</h3>
                                <a href="tel:33 23 48 93 29">33 23 48 93 29</a>
                                <p>Paseo del Norte 5605 Colonia San Juan de Ocotán Zapopan, Jalisco.</p>
                            </address>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Grid container className="copyright">
                2021 © KOBER TODOS LOS DERECHOS RESERVADOS.
            </Grid>
        </footer>
    )
}

// mapStateToProps =>
function mapStateToProps(state){
    return state;
}

// Bind actions to be used along redux =>
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        // Actions
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(Footer);